import React from 'react';
import useQuery from '../utilities/useQuery';
import {graphql, useMutation} from 'react-relay/hooks';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore} from './authorization';

const RAZZLE_APP_REDIRECT_URI = process.env.RAZZLE_APP_REDIRECT_URI;

export default React.memo(() => {
    const {code} = useQuery();
    const authorizationDispatch = useAuthorizationDispatch();
    const authorizationStore = useAuthorizationStore();
    const [apply, applying] = useMutation(
        graphql`                                                    
            mutation callback1ComponentsGoogleCodeMutation($request: GoogleCodeRequestInput) {
                googleCode(request: $request) {
                    tokenType
                    accessToken
                    refreshToken
                }
            }
      `);
    React.useEffect(() => {
        apply({
            variables: {request: {code, redirectUri: RAZZLE_APP_REDIRECT_URI}},
            onCompleted: (payload) => {
                authorizationStore.set(tokenType__, payload.googleCode.tokenType);
                authorizationStore.set(accessToken__, payload.googleCode.accessToken);
                authorizationStore.set(refreshToken__, payload.googleCode.refreshToken);
                authorizationDispatch();
            },
            onError: (payload) => {}
        });
    }, [apply, code, authorizationDispatch, authorizationStore]);
    return (
        <>Callback......</>
    );
});