// @react-oauth/google

import React from 'react';
import { useCallback, useEffect, useRef } from 'react';

export default function useGoogleLogin({
  flow = 'implicit',
  scope = '',
  onSuccess,
  onError,
  onNonOAuthError,
  overrideScope,
  state,
  ...props
}) {
  const clientId = '34767676289-1p9subiftl43qk7ptos4qufoe2d9bj61.apps.googleusercontent.com';
  const clientRef = useRef;
  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;
  const onNonOAuthErrorRef = useRef(onNonOAuthError);
  onNonOAuthErrorRef.current = onNonOAuthError;
  useEffect(() => {
    const clientMethod =
      flow === 'implicit' ? 'initTokenClient' : 'initCodeClient';
    const client = window?.google?.accounts.oauth2[clientMethod]({
      client_id: clientId,
      scope: overrideScope ? scope : `openid profile email ${scope}`,
      callback: (response) => {
        if (response.error) return onErrorRef.current?.(response);

        onSuccessRef.current?.(response);
      },
      error_callback: (nonOAuthError) => {
        onNonOAuthErrorRef.current?.(nonOAuthError);
      },
      state,
      ...props,
    });
    clientRef.current = client;
  }, [clientId, flow, scope, state]);
  const loginImplicitFlow = useCallback(
    (overrideConfig) =>
      clientRef.current?.requestAccessToken(overrideConfig),
    [],
  );
  const loginAuthCodeFlow = useCallback(
    () => clientRef.current?.requestCode(),
    [],
  );
  return flow === 'implicit' ? loginImplicitFlow : loginAuthCodeFlow;
}