import React from 'react';
import useQuery from '../utilities/useQuery';
import {useLocation} from 'react-router-dom';
import searchFilter from '../utilities/searchFilter';
import classNames from 'classnames';

export default React.memo(() => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    return (
        <div className='display-flex'>
            <a href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}>
                <div className={classNames('padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem text-sm medium color-gray-700 border-radius-0dot5rem', {
                    'background-color-gray-50': locale === 'uk' 
                })}>UA</div>
            </a>
            <a
                className='margin-left-0dot5rem'
                href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
            >
                <div className={classNames('padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem text-sm medium color-gray-700 border-radius-0dot5rem', {
                    'background-color-gray-50': locale === 'en'
                })}>EN</div>
            </a>
        </div>
    );
});