import React, {Suspense} from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import FallbackComponent from './fallbackComponent';
import Skeleton from './skeleton/skeleton';

const ContactsImpl = React.memo(() => {
  const data = useLazyLoadQuery(
    graphql`
        query contactsComponentsQuery($id: String) {
            endpointById(id: $id) {
                id
                name
            }
        }
    `,
    {id: "5cbb88642c29355ba1ea529d_"}
  );
  return (
    <>
    <Helmet>
        <title>Welcome to Contacts</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    {data.endpointById.id} {data.endpointById.name}
    </>
  );
});

export default React.memo(() => {
  return (
    <Suspense fallback={<Skeleton/>}>
      <ErrorBoundary {...{FallbackComponent}}>
        <ContactsImpl/>
      </ErrorBoundary>
    </Suspense>
  );
});

