import React from 'react';
import useQuery from '../utilities/useQuery';
import {graphql, useMutation} from 'react-relay/hooks';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore} from './authorization';

export default React.memo(() => {
    const {token} = useQuery();
    const authorizationDispatch = useAuthorizationDispatch();
    const authorizationStore = useAuthorizationStore();
    const [apply, applying] = useMutation(
        graphql`                                                    
            mutation callback2ComponentsGoogleTokenMutation($request: GoogleTokenRequestInput) {
                googleToken(request: $request) {
                    tokenType
                    accessToken
                    refreshToken
                }
            }
      `);
    React.useEffect(() => {
        apply({
            variables: {request: {token}},
            onCompleted: (payload) => {
                authorizationStore.set(tokenType__, payload.googleToken.tokenType);
                authorizationStore.set(accessToken__, payload.googleToken.accessToken);
                authorizationStore.set(refreshToken__, payload.googleToken.refreshToken);
                authorizationDispatch();
            },
            onError: (payload) => {}
        });
    }, [apply, token, authorizationDispatch, authorizationStore]);
    return (
        <>Callback...</>
    );
});