/**
 * @generated SignedSource<<3e9c6fe408f7c7d5d4448c65a9b66fba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "whoamiNext",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "scopeComponentsWhoamiNextQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "scopeComponentsWhoamiNextQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "358521c97c1b4113975dcd985f23c2f8",
    "id": null,
    "metadata": {},
    "name": "scopeComponentsWhoamiNextQuery",
    "operationKind": "query",
    "text": "query scopeComponentsWhoamiNextQuery {\n  whoamiNext {\n    id\n    username\n    email\n    firstName\n  }\n}\n"
  }
};
})();

node.hash = "1289b645b8ff1052c5e3bd500cff0a17";

module.exports = node;
