import React from 'react';
import useQuery from '../utilities/useQuery';
import {useNavigate} from 'react-router-dom';
import searchFilter from '../utilities/searchFilter';

export default React.memo(() => {
    const navigate = useNavigate();
    const {code, state = ''} = useQuery();
    React.useEffect(() => {
        navigate(`/callback1${searchFilter(state, {code})}`);
    }, [navigate, code, state]);
    return (
        <>Callback...</>
    );
});