import React, {Suspense} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import FallbackComponent from './fallbackComponent';
import ContentSvg from '../assets/content.svg';
import SocialIconGoogleSvg from '../assets/social-icon-google.svg';
// import SocialIconFbSvg from '../assets/social-icon-fb.svg';
import classNames from 'classnames';
import {useIntl, FormattedMessage} from 'react-intl';
import {useForm} from 'react-hook-form';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../utilities/searchFilter';
import Skeleton from './skeleton/skeleton';
import {Helmet} from 'react-helmet-async';
import {graphql, useMutation} from 'react-relay/hooks';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore} from './authorization';
import useMountedState from '../utilities/useMountedState';
import Locale from './locale';
import useGoogleLogin from '../utilities/useGoogleLogin';

const RAZZLE_APP_GRECAPTCHA = process.env.RAZZLE_APP_GRECAPTCHA;
const RAZZLE_APP_REDIRECT_URI = process.env.RAZZLE_APP_REDIRECT_URI;

const SignIn = React.memo(({tunnel}) => {
  const intl = useIntl();
  const authorizationDispatch = useAuthorizationDispatch();
  const authorizationStore = useAuthorizationStore();
  const location = useLocation();
  const [stage, setStage] = React.useState(0);
  const [grecaptcha, setGrecaptcha] = useMountedState(false);
  const {register, handleSubmit, formState: {errors}, setFocus} = useForm();
  const [error, setError] = React.useState();
  const [apply, applying] = useMutation(
    graphql`
        mutation signInComponentsTokenMutation($request: TokenRequestInput) {
            token(request: $request) {
              tokenType
              accessToken
              refreshToken
            }
        }
  `);
  const submit = handleSubmit(data => {
    setError(false);
    if (stage === 0) {
      setStage(1);
      return;
    }
    setGrecaptcha(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(RAZZLE_APP_GRECAPTCHA, {action: 'submit'}).then((token) => {
        apply({
          variables: {request: {...data, reCaptcha: token}},
          onCompleted: (payload) => {
            authorizationStore.set(tokenType__, payload.token.tokenType);
            authorizationStore.set(accessToken__, payload.token.accessToken);
            authorizationStore.set(refreshToken__, payload.token.refreshToken);
            authorizationDispatch();
          },
          onError: (payload) => {setError(payload);}
        });
        setGrecaptcha(false);
      });
    });
  });
  React.useEffect(() => {
    if (stage === 1) setFocus('password');
  }, [setFocus, stage]);
  React.useEffect(() => {
    setFocus('username');
  }, [setFocus]);
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: RAZZLE_APP_REDIRECT_URI,
    state: searchFilter(location.search)
  });
  return (
    <>
    <Helmet>
        <title>Welcome to Ottry sign in</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <div className='mw768-max-width-80rem mw768-margin-0-auto padding-left-1dot5rem mw768-padding-left-2rem padding-right-1dot5rem mw768-padding-right-2rem'>
      <div className='mw768-min-height-100vh mw768-display-flex mw768-justify-content-center mw768-align-items-center'>
        <div className='mw768-flex-1 mw768-max-width-25rem'>
          <div className='padding-top-4rem mw768-padding-top-0 display-flex justify-content-center'>
            <ContentSvg className='display-block height-3rem width-3rem'/>
          </div>
          <div className='padding-top-1dot5rem display-flex justify-content-center'>
            <span className='display-xs semibold color-gray-900'>
              <FormattedMessage defaultMessage='Sign in to Ottry account'/>
            </span>
          </div>
          <div className='padding-top-0dot5rem display-flex justify-content-center'>
            <span className='text-md color-gray-500'>
              <FormattedMessage defaultMessage='Welcome back! Enter your data'/>
            </span>
          </div>
          <div className='padding-top-2rem'>
            <div 
              style={{boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'}}
              className='cursor-pointer border-radius-0dot5rem border-1px-solid border-color-gray-300 padding-top-0dot625rem padding-bottom-0dot625rem display-flex justify-content-center align-items-center'
              onClick={() => {
                window.name === 'null0' && tunnel?.wc?.detach();
                googleLogin();
              }}
            >
              <SocialIconGoogleSvg className='display-block width-1dot5rem height-1dot5rem'/>
              <span className='padding-left-0dot75rem text-md medium color-gray-700'>
                <FormattedMessage defaultMessage='Continue with Google'/>
              </span>
            </div>
          </div>
          {/* <div className='padding-top-0dot75rem'>
            <div
              style={{boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'}}
              className='cursor-pointer border-radius-0dot5rem border-1px-solid border-color-gray-300 padding-top-0dot625rem padding-bottom-0dot625rem display-flex justify-content-center align-items-center'
            >
              <SocialIconFbSvg className='display-block width-1dot5rem height-1dot5rem'/>
              <span className='padding-left-0dot75rem text-md medium color-gray-700'>Продовжити з Facebook</span>
            </div>
          </div> */}
          <div className='margin-top-1dot5rem'>
            <div className='display-flex align-items-center'>
              <div className='border-bottom-1px-solid border-color-gray-200 height-0dot0625rem flex-1'>&nbsp;</div>
              <span className='text-sm color-gray-500 padding-left-0dot5rem padding-right-0dot5rem'>
                <FormattedMessage defaultMessage='OR'/>
              </span>
              <div className='border-bottom-1px-solid border-color-gray-200 height-0dot0625rem flex-1'>&nbsp;</div>
            </div>
          </div>
          {error && 
          <div className='padding-top-1dot25rem'>
            <span className='color-error-500 text-sm'>
              <FormattedMessage defaultMessage='An error occurred! Please check the data and try again.'/>
            </span>
          </div>
          }
          <form className='form' onSubmit={submit}>
            <div className='padding-top-1dot25rem'>
              <span className='text-sm medium color-gray-700'>
                <FormattedMessage defaultMessage='Email'/>
              </span>
            </div>
            <div className='padding-top-0dot375rem'>
              <div className={classNames('border-1px-solid border-color-gray-300 border-radius-0dot5rem display-flex align-items-center input', {'errors': errors.username})}>
                  <div className='flex-1 padding-top-0dot625rem padding-bottom-0dot625rem'>
                      <input
                          className='width-100percent text-md color-gray-500 text-indent-0dot875rem'
                          type='text'
                          placeholder={intl.formatMessage({defaultMessage: 'Enter your email'})}
                          {...register('username', {required: true, pattern: /^\S+@\S+\.\S+$/})}
                      />
                  </div>
              </div>
              {errors.username && errors.username.type === 'required' && <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='This field is required'/></div>}
              {errors.username && errors.username.type === 'pattern' && <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='This field is not valid'/></div>}
            </div>
            {stage === 1 &&
            <>
            <div className='padding-top-1dot25rem'>
              <span className='text-sm medium color-gray-700'>
                <FormattedMessage defaultMessage='Password'/>
              </span>
            </div>
            <div className='padding-top-0dot375rem'>
              <div className={classNames('border-1px-solid border-color-gray-300 border-radius-0dot5rem display-flex align-items-center input', {'errors': errors.password})}>
                  <div className='flex-1 padding-top-0dot625rem padding-bottom-0dot625rem'>
                      <input
                          className='width-100percent text-md color-gray-500 text-indent-0dot875rem'
                          type='password'
                          placeholder={intl.formatMessage({defaultMessage: 'Enter your password'})}
                          {...register('password', {required: true})}
                      />
                  </div>
              </div>
              {errors.password && errors.password.type === 'required' && <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='This field is required'/></div>}
            </div>
            <div className='padding-top-1dot5rem padding-bottom-0dot5rem'>
                <div className='display-flex justify-content-flex-end'>
                  <Link
                    className='text-sm medium color-primary-600'
                    to={`/reset${searchFilter(location.search)}`}
                  ><FormattedMessage defaultMessage='Forgot my password'/></Link>
                </div>
            </div>
            </>
            }
            <div className='padding-top-1rem'>
              <input 
                type='submit' 
                value={`${stage === 0 ? 
                  intl.formatMessage({defaultMessage: 'Continue with email'}) :
                  intl.formatMessage({defaultMessage: 'Sign in'})
                }${(grecaptcha || applying) ? '...' : ''}`}
                style={{
                  background: 'linear-gradient(88.92deg, #1570EF 0%, #2E90FA 100%)',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.1)'
                }}
                className='cursor-pointer width-100percent border-radius-0dot5rem padding-top-0dot625rem padding-bottom-0dot625rem display-flex justify-content-center align-items-center text-md medium color-white'
              />
            </div>
          </form>
          <div className='padding-top-2rem'>
              <div className='display-flex'>
                  <span className='flex-1 text-sm color-gray-500 display-flex justify-content-flex-end'>
                    <FormattedMessage defaultMessage='Do not have an account?'/>
                  </span>
                  <span className='width-0dot25rem'/>
                  <Link
                    className='flex-1 text-sm medium color-primary-600'
                    to={`/sign-up${searchFilter(location.search)}`}
                  ><FormattedMessage defaultMessage='Sign up'/></Link>
              </div>
          </div>
          <div className='padding-top-2rem'>
            <Locale/>
          </div>
        </div>
      </div>
    </div>
    </>
  );
});

export default React.memo(({tunnel}) => {
  return (
    <Suspense fallback={<Skeleton/>}>
      <ErrorBoundary {...{FallbackComponent}}>
        <SignIn {...{tunnel}} />
      </ErrorBoundary>
    </Suspense>
  );
});

