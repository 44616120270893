import React, { Suspense } from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import FallbackComponent from './fallbackComponent';
import Skeleton from './skeleton/skeleton';
import ContentSvg from '../assets/content.svg';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import searchFilter from '../utilities/searchFilter';
import {exists, parse} from '../utilities/qs';
import Locale from './locale';
import {FormattedMessage} from 'react-intl';
import {accessToken__, refreshToken__, tokenType__} from './authorization';

const RAZZLE_APP_UAF_REDIRECT_URI = process.env.RAZZLE_APP_UAF_REDIRECT_URI;
const RAZZLE_APP_UAF_REDIRECT_URI_WWW = process.env.RAZZLE_APP_UAF_REDIRECT_URI_WWW;
const RAZZLE_APP_S_UAF_REDIRECT_URI = process.env.RAZZLE_APP_S_UAF_REDIRECT_URI;
const RAZZLE_APP_S_UAF_REDIRECT_URI_WWW = process.env.RAZZLE_APP_S_UAF_REDIRECT_URI_WWW;
const RAZZLE_APP_TTE_REDIRECT_URI = process.env.RAZZLE_APP_TTE_REDIRECT_URI;
const RAZZLE_APP_TTE_REDIRECT_URI_WWW = process.env.RAZZLE_APP_TTE_REDIRECT_URI_WWW;
const RAZZLE_APP_TTET_REDIRECT_URI = process.env.RAZZLE_APP_TTET_REDIRECT_URI;
const RAZZLE_APP_TTET_REDIRECT_URI_WWW = process.env.RAZZLE_APP_TTET_REDIRECT_URI_WWW;
const RAZZLE_APP_I0_REDIRECT_URI = process.env.RAZZLE_APP_I0_REDIRECT_URI;
const RAZZLE_APP_I0_REDIRECT_URI_WWW = process.env.RAZZLE_APP_I0_REDIRECT_URI_WWW;
const RAZZLE_APP_TLC_REDIRECT_URI = process.env.RAZZLE_APP_TLC_REDIRECT_URI;
const RAZZLE_APP_TLC_REDIRECT_URI_WWW = process.env.RAZZLE_APP_TLC_REDIRECT_URI_WWW;

const allowed = [
  RAZZLE_APP_UAF_REDIRECT_URI, RAZZLE_APP_UAF_REDIRECT_URI_WWW,
  RAZZLE_APP_S_UAF_REDIRECT_URI, RAZZLE_APP_S_UAF_REDIRECT_URI_WWW,
  RAZZLE_APP_TTE_REDIRECT_URI, RAZZLE_APP_TTE_REDIRECT_URI_WWW,
  RAZZLE_APP_TTET_REDIRECT_URI, RAZZLE_APP_TTET_REDIRECT_URI_WWW,
  RAZZLE_APP_I0_REDIRECT_URI, RAZZLE_APP_I0_REDIRECT_URI_WWW,
  RAZZLE_APP_TLC_REDIRECT_URI, RAZZLE_APP_TLC_REDIRECT_URI_WWW,
  'http://localhost:3000/callback' // local development envs
];

const Scope = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  // const {from} = location.state || {from: null};
  const from = exists(location, 'from');
  const {whoamiNext} = useLazyLoadQuery(
    graphql`
        query scopeComponentsWhoamiNextQuery {
            whoamiNext {
                id
                username
                email
                firstName
            }
        }
    `
  );
  const [apply, applying] = useMutation(
    graphql`                                                    
        mutation scopeComponentsScopeMutation($request: ScopeRequestInput) {
            scope(request: $request) {
                tokenType
                accessToken
                refreshToken
            }
        }
  `);
  const [loading, setLoading] = React.useState(false);

  const locationRef = React.useRef();
  locationRef.current = location;

  const auth = React.useCallback(() => {
    setLoading(true);
    const lcl = exists(locationRef.current, 'locale');
    const crrnc = exists(locationRef.current, 'currency');
    const ppl = exists(locationRef.current, 'apply');
    apply({
      variables: {request: {scope: parse(locationRef.current, 'scope')}},
      onCompleted: (payload) => {
        setTimeout(() => {
          if (allowed.includes(parse(locationRef.current, 'redirect'))) {
            window.location.href =
              parse(locationRef.current, 'redirect')
              + '?continue=' + encodeURIComponent(parse(locationRef.current, 'continue'))
              + (lcl ? '&locale=' + parse(locationRef.current, 'locale') : '')
              + (crrnc ? '&currency=' + parse(locationRef.current, 'currency') : '')
              + (ppl ? '&apply=' + parse(locationRef.current, 'apply') : '')
              + `#?${tokenType__}=${payload.scope.tokenType}`
              + `&${accessToken__}=${payload.scope.accessToken}`
              + `&${refreshToken__}=${payload.scope.refreshToken}`
            ;
          }
        }, 500);
      },
      onError: (payload) => {}
    });
  }, [locationRef]);
  React.useEffect(() => {
    if (from) {
      auth();
    }
  }, [from, auth]);
  if (from) {
    return 'Loading...';
  }
  return (
    <>
    <div className='padding-top-4rem mw768-padding-top-0 display-flex justify-content-center'>
      <ContentSvg className='display-block height-3rem width-3rem'/>
    </div>
    <div className='padding-top-1dot5rem display-flex justify-content-center'>
      <span className='display-xs semibold color-gray-900'>
        <FormattedMessage defaultMessage='Login to Ottry account'/>
      </span>
    </div>
    <div className='padding-top-0dot5rem display-flex justify-content-center'>
      <span className='text-md color-gray-500'>
        <FormattedMessage defaultMessage='Confirm login'/>
      </span>
    </div>
    <div className='padding-top-1rem'>
      <div
        style={{
          background: 'linear-gradient(88.92deg, #1570EF 0%, #2E90FA 100%)',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.1)'
        }}
        className='cursor-pointer width-100percent border-radius-0dot5rem padding-top-0dot625rem padding-bottom-0dot625rem display-flex justify-content-center align-items-center'
        onClick={() => !loading && auth()}
      >
        <span className='text-md medium color-white'>
          <FormattedMessage defaultMessage='Confirm'/>{loading && '...'}
        </span>
      </div>
    </div>
    <div className='padding-top-2rem'>
      <div className='display-flex'>
          <span className='text-sm color-gray-500'>
            {whoamiNext.username || whoamiNext.email}
          </span>
          <span className='width-0dot25rem'/>
          <span
            className='cursor-pointer text-sm medium color-primary-600'
            onClick={() => navigate(`/sign-out${searchFilter(location.search)}`)}
          ><FormattedMessage defaultMessage='Sign out'/></span>
      </div>
    </div>
    <div className='padding-top-2rem'>
      <Locale/>
    </div>
    </>
  );
});

const Boom = ({error}) => {
  const location = useLocation();
  if (error?.toString()?.includes('Access Denied')) {
    return (
      <>
        Looks your token is expired. <Link to={`/sign-out${searchFilter(location.search)}`}>Please sign in again.</Link>
      </>
    );
  }
  throw error;
};

export default React.memo(() => {
  return (
    <>
    <Helmet>
        <title>Welcome to Ottry scope</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <div className='mw768-max-width-80rem mw768-margin-0-auto padding-left-1dot5rem mw768-padding-left-2rem padding-right-1dot5rem mw768-padding-right-2rem'>
      <div className='mw768-min-height-100vh mw768-display-flex mw768-justify-content-center mw768-align-items-center'>
        <div className='mw768-flex-1 mw768-max-width-25rem'>
          <Suspense fallback={
            <div className='padding-top-4rem mw768-padding-top-0'>
              <Skeleton/>
            </div>
          }>
            <ErrorBoundary {...{FallbackComponent}}>
              <ErrorBoundary {...{FallbackComponent: Boom}}>
                <Scope/>
              </ErrorBoundary>
            </ErrorBoundary>
          </Suspense>
        </div>
      </div>
    </div>
    </>
  );
});

