import 'isomorphic-fetch';
import Application from './components/application/application';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import createRelayEnvironment from './utilities/createRelayEnvironment';
import Cookies from 'js-cookie';
import {AuthorizationProvider} from './components/authorization';
import {HelmetProvider} from 'react-helmet-async';

const store = {
  get: function(key) {return Cookies.get(key);},
  set: function(key, value) {Cookies.set(key, value, {expires: 365, path: '/'});},
  remove: function(key) {Cookies.remove(key, {path: '/'});},
};

hydrateRoot(
  document.getElementById('root'),
  <RelayEnvironmentProvider 
    environment={createRelayEnvironment(
      () => ({'Content-Type': 'application/json'}), store)}
  >
    <BrowserRouter>
      <HelmetProvider>
        <AuthorizationProvider {...{store}}>
          <Application/>
        </AuthorizationProvider>
      </HelmetProvider>
    </BrowserRouter>
  </RelayEnvironmentProvider>
  // , { onRecoverableError: (e) => {} }
);

if (module.hot) {
  module.hot.accept();
}
