import React from 'react';
import './application.scss';
import Ogle from '../theme/ogle';
import {Route, Routes, useLocation} from 'react-router-dom';
import Home from '../home';
import Contacts from '../contacts';
import {Helmet} from 'react-helmet-async';
import useQuery from '../../utilities/useQuery';
import {IntlProvider} from 'react-intl';
import SignIn from '../signIn';
import SignUp from '../signUp';
import Reset from '../reset';
import Endpoint from '../endpoint';
import Scope from '../scope';
import en from '../../../compiled-lang/en.json';
import uk from '../../../compiled-lang/uk.json';
import {ErrorBoundary} from 'react-error-boundary';
import FallbackComponent from '../fallbackComponent';
import {useAuthorizationState} from '../authorization';
import searchFilter from '../../utilities/searchFilter';
import Redirect from '../redirect';
import SignOut from '../signOut';
import WinChan from 'winchan';
import Callback from '../callback';
import Callback1 from '../callback1';
import {exists} from '../../utilities/qs';
import CookieConsent from '../cookie-consent';
import Callback2 from '../callback2';

const RAZZLE_APP_SERVICE = process.env.RAZZLE_APP_SERVICE;

const locales = {en, uk};

const IntlProviderUrlConnected = React.memo(({children}) => {
  const {locale = 'uk'} = useQuery();
  return (
      <IntlProvider locale={locale} defaultLocale='en' messages={locales[locale]}>
          {children}
      </IntlProvider>
  );
});

// const SignIn = React.lazy(() => import('../signIn'));
// const SuspenseLazy = React.memo(({children}) => 
//     <React.Suspense fallback={'Route loading...'}>
//       {children}
//     </React.Suspense>);

const Application = React.memo(() => {
  const [tunnel, setTunnel] = React.useState(null);
  React.useEffect(() => {
    if (window.name === 'null0') {
        const wc = WinChan.onOpen((origin, args, cb) => {
            // service is using popup only
            // origin is the scheme+host+port that cause window invocation,
            // it can be trusted
            
            // TODO uncomment next two!
            if (RAZZLE_APP_SERVICE.indexOf(origin) !== 0) // Port is not sent if it is default e.g. 80.
               return;

            // args are the untrusted arguments provided by the calling site

            // and cb you can call within the function, or synchronously later.
            // calling it indicated the window is done and can be closed.
            setTunnel({wc: wc, cb: cb});
        });
    }
  }, []);
  const location = useLocation();
  const authorizationState = useAuthorizationState();
  const cntn = exists(location, 'continue');
  const scp = exists(location, 'scope');
  const crt = exists(location, 'create');
  return (
    <>
    <Helmet>
        <title>Welcome to Ottry accounts</title>
        <meta name='description' content='Web site of your dream'/>
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png'/>
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png'/>
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png'/>
        <link rel='manifest' href='/site.webmanifest'/>
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#1570ef'/>
        <meta name='msapplication-TileColor' content='#da532c'/>
        <meta name='theme-color' content='#ffffff'/>
    </Helmet>
    <CookieConsent/>
    <Ogle>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/contacts' element={<Contacts/>}/>
        {/* <Route path='/sign-in' element={<SuspenseLazy><SignIn/></SuspenseLazy>}/> */}
        <Route path='/sign-in' element={
          authorizationState ?
            <Redirect 
              to={`/${cntn ? (scp ? 'scope' : 'endpoint') : ''}${searchFilter(location.search, {from: 'sign-in'})}`}
              // state={{from: location}}
            /> : 
            <SignIn {...{tunnel}}/>
        }/>
        <Route path='/sign-up' element={
          authorizationState ?
            <Redirect to={`/${cntn ? (scp ? 'scope' : 'endpoint') : ''}${searchFilter(location.search)}`}/> : 
            <SignUp {...{tunnel}}/>
        }/>
        <Route path='/reset' element={
          authorizationState ?
            <Redirect to={`/${cntn ? (scp ? 'scope' : 'endpoint') : ''}${searchFilter(location.search)}`}/> : 
            <Reset/>
        }/>
        <Route path='/endpoint' element={
          authorizationState ?
            <Endpoint {...{tunnel}}/> : 
            <Redirect to={`/sign-in${searchFilter(location.search)}`}/>
        }/>
        <Route path='/scope' element={
          authorizationState ?
            <Scope/> : 
            <Redirect to={`/${crt ? 'sign-up' : 'sign-in'}${searchFilter(location.search)}`}/>
        }/>
        <Route path='/sign-out' element={<SignOut/>} />
        <Route path='/callback' element={<Callback/>}/>
        <Route path='/callback1' element={
          authorizationState ?
            <Redirect 
              to={`/${cntn ? (scp ? 'scope' : 'endpoint') : ''}${searchFilter(location.search, {from: 'callback1'})}`}
            /> : 
            <Callback1/>
        }/>
        <Route path='/callback2' element={
          authorizationState ?
            <Redirect 
              to={`/${cntn ? (scp ? 'scope' : 'endpoint') : ''}${searchFilter(location.search, {from: 'callback2'})}`}
            /> : 
            <Callback2/>
        }/>
      </Routes>
    </Ogle>
    </>
  );
});

export default React.memo(() => (
    <IntlProviderUrlConnected>
      <ErrorBoundary {...{FallbackComponent}}>
        <Application/>
      </ErrorBoundary>
    </IntlProviderUrlConnected>
));