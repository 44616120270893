import React, { Suspense } from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import FallbackComponent from './fallbackComponent';
import Skeleton from './skeleton/skeleton';
import ContentSvg from '../assets/content.svg';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import searchFilter from '../utilities/searchFilter';
import {exists, parse} from '../utilities/qs';
import Locale from './locale';
import {FormattedMessage} from 'react-intl';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationStore} from './authorization';

const RAZZLE_APP_MARKETPLACE = process.env.RAZZLE_APP_MARKETPLACE;
const RAZZLE_APP_NEW_MARKETPLACE = process.env.RAZZLE_APP_NEW_MARKETPLACE;

const Endpoint = React.memo(({tunnel}) => {
  const authorizationStore = useAuthorizationStore();
  const navigate = useNavigate();
  const location = useLocation();
  // const {from} = location.state || {from: null};
  const from = exists(location, 'from');
  const {whoamiNext} = useLazyLoadQuery(
    graphql`
        query endpointComponentsWhoamiNextQuery {
            whoamiNext {
                id
                username
                email
                firstName
            }
        }
    `
  );
  const [loading, setLoading] = React.useState(false);
  const locationRef = React.useRef();
  locationRef.current = location;
  const auth = React.useCallback(() => {
    setLoading(true);
    const nw = exists(locationRef.current, 'new');
    const lcl = exists(locationRef.current, 'locale');
    const crrnc = exists(locationRef.current, 'currency');
    const ppl = exists(locationRef.current, 'apply');
    setTimeout(() => {
        window.name === 'null0' ?
            tunnel.cb(JSON.stringify({
                [tokenType__]: authorizationStore.get(tokenType__),
                [accessToken__]: authorizationStore.get(accessToken__),
                [refreshToken__]: authorizationStore.get(refreshToken__)
            }))
            :
            window.location.href =
                (nw ? RAZZLE_APP_NEW_MARKETPLACE : RAZZLE_APP_MARKETPLACE) + '/callback'
                + '?continue=' + encodeURIComponent(parse(locationRef.current, 'continue'))
                + (lcl ? '&locale=' + parse(locationRef.current, 'locale') : '')
                + (crrnc ? '&currency=' + parse(locationRef.current, 'currency') : '')
                + (ppl ? '&apply=' + parse(locationRef.current, 'apply') : '')
                + `#?${tokenType__}=${authorizationStore.get(tokenType__)}`
                + `&${accessToken__}=${authorizationStore.get(accessToken__)}`
                + `&${refreshToken__}=${authorizationStore.get(refreshToken__)}`
        ;
    }, 500);
  }, [locationRef, tunnel]);
  const [flag, setFlag] = React.useState(true);
  React.useEffect(() => {
    setFlag(window.name === 'null0' && !tunnel);
  }, [tunnel]);
  React.useEffect(() => {
    if (from) {
      auth();
    }
  }, [from, auth]);
  if (from) {
    return 'Loading...';
  }
  return (
    <>
    <div className='padding-top-4rem mw768-padding-top-0 display-flex justify-content-center'>
      <ContentSvg className='display-block height-3rem width-3rem'/>
    </div>
    <div className='padding-top-1dot5rem display-flex justify-content-center'>
      <span className='display-xs semibold color-gray-900'>
        <FormattedMessage defaultMessage='Login to Ottry account'/>
      </span>
    </div>
    <div className='padding-top-0dot5rem display-flex justify-content-center'>
      <span className='text-md color-gray-500'>
        <FormattedMessage defaultMessage='Confirm login'/>
      </span>
    </div>
    <div className='padding-top-1rem'>
      <div
        style={{
          background: 'linear-gradient(88.92deg, #1570EF 0%, #2E90FA 100%)',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.1)'
        }}
        className='cursor-pointer width-100percent border-radius-0dot5rem padding-top-0dot625rem padding-bottom-0dot625rem display-flex justify-content-center align-items-center'
        onClick={() => !flag && !loading && auth()}
      >
        <span className='text-md medium color-white'>
          <FormattedMessage defaultMessage='Confirm'/>{(loading || flag) && '...'}
        </span>
      </div>
    </div>
    <div className='padding-top-2rem'>
      <div className='display-flex'>
          <span className='text-sm color-gray-500'>
            {whoamiNext.username || whoamiNext.email}
          </span>
          <span className='width-0dot25rem'/>
          <span
            className='cursor-pointer text-sm medium color-primary-600'
            onClick={() => {
              window.name === 'null0' && tunnel?.wc?.detach();
              navigate(`/sign-out${searchFilter(location.search)}`);
            }}
          ><FormattedMessage defaultMessage='Sign out'/>{flag && '...'}</span>
      </div>
    </div>
    <div className='padding-top-2rem'>
      <Locale/>
    </div>
    </>
  );
});

const Boom = ({error}) => {
  const location = useLocation();
  if (error?.toString()?.includes('Access Denied')) {
    return (
      <>
        Looks your token is expired. <Link to={`/sign-out${searchFilter(location.search)}`}>Please sign in again.</Link>
      </>
    );
  }
  throw error;
};

export default React.memo(({tunnel}) => {
  return (
    <>
    <Helmet>
        <title>Welcome to Ottry endpoint</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <div className='mw768-max-width-80rem mw768-margin-0-auto padding-left-1dot5rem mw768-padding-left-2rem padding-right-1dot5rem mw768-padding-right-2rem'>
      <div className='mw768-min-height-100vh mw768-display-flex mw768-justify-content-center mw768-align-items-center'>
        <div className='mw768-flex-1 mw768-max-width-25rem'>
          <Suspense fallback={
            <div className='padding-top-4rem mw768-padding-top-0'>
              <Skeleton/>
            </div>
          }>
            <ErrorBoundary {...{FallbackComponent}}>
              <ErrorBoundary {...{FallbackComponent: Boom}}>
                <Endpoint {...{tunnel}}/>
              </ErrorBoundary>
            </ErrorBoundary>
          </Suspense>
        </div>
      </div>
    </div>
    </>
  );
});

