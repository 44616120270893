// https://github.com/relay-tools/react-relay-network-modern
import 'regenerator-runtime/runtime';

import {RelayNetworkLayer, urlMiddleware} from 'react-relay-network-modern';
import {Environment, RecordSource, Store} from 'relay-runtime';
import authMiddleware from './authMiddleware';

const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;
const tokenType__ = 'tokenType';
const accessToken__ = 'accessToken';
const refreshToken__ = 'refreshToken';

const refresh = async (refreshToken) => {
    const query = graphql`
      mutation createRelayEnvironmentMutation($request: RefreshRequestInput) {
        refresh(request: $request) {
            tokenType
            accessToken
            refreshToken
        }
      }
    `;
    const variables = {request: {refreshToken}};
    const response = await fetch(`${RAZZLE_APP_PREFIX}/graphql`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify({query: query.params.text, variables}),
    });
    const {data, errors} = await response.json();
    if (errors) {
        throw new Error(errors[0].message);
    }
    return data.refresh;
};

export default function createRelayEnvironment(headers, store) {
    const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;
    return new Environment({
        network: new RelayNetworkLayer([
            urlMiddleware({
                url: `${RAZZLE_APP_PREFIX}/graphql`,
                headers
            }),
            // https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/
            // https://auth0.com/blog/securing-single-page-applications-with-refresh-token-rotation/
            authMiddleware({
                allowEmptyToken: true,
                token: () => store.get(accessToken__),
                tokenRefreshPromise: async () => {
                  try {
                        const payload = await refresh(store.get(refreshToken__));
                        store.set(tokenType__, payload.tokenType);
                        store.set(accessToken__, payload.accessToken);
                        store.set(refreshToken__, payload.refreshToken);
                        return payload?.accessToken;
                    } catch (err) {
                        return;
                    }
                },
              })
            // , loggerMiddleware()
        ]),
        store: new Store(new RecordSource()),
    });
}
