import React, { Suspense } from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
// import {Link, useLocation} from 'react-router-dom';
import FallbackComponent from './fallbackComponent';
import Skeleton from './skeleton/skeleton';
// import searchFilter from '../utilities/searchFilter';
import Locale from './locale';

const Home = React.memo(() => {
  // const location = useLocation();
  return (
    <>
    <Helmet>
        <title>Welcome to Accounts home</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <div>
      {/* <Link to={`/${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Home'/></Link> */}
      {/* <Link to={`/sign-in${searchFilter(location.search)}`}>Sign in</Link> */}
      {/* <Link to={`/sign-up${searchFilter(location.search)}`}>Sign up</Link> */}
      {/* <Link to={`/sign-out${searchFilter(location.search)}`}>Sign out</Link> */}
      {/* <Link to={`/contacts${searchFilter(location.search)}`}>Contacts</Link> */}
      {/* <Link to={`/endpoint${searchFilter(location.search)}`}>Endpoint</Link> */}
    </div>
    <div>
      Comming soon...
    </div>
    <div className='padding-top-2rem'>
      <Locale/>
    </div>
    </>
  );
});

export default React.memo(() => {
  return (
    <Suspense fallback={<Skeleton/>}>
      <ErrorBoundary {...{FallbackComponent}}>
        <Home/>
      </ErrorBoundary>
    </Suspense>
  );
});

