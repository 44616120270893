import React, { Suspense } from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
import FallbackComponent from './fallbackComponent';
import {exists} from '../utilities/qs';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
const RAZZLE_APP_SIGNOUT = process.env.RAZZLE_APP_SIGNOUT;

const SignOut = React.memo(() => {
    const location = useLocation();
    const locationRef = React.useRef();
    locationRef.current = location;
    React.useEffect(() => {
        const cntn = exists(locationRef.current, 'continue');
        const scp = exists(locationRef.current, 'scope');
        setTimeout(
            () => window.location.href =
              RAZZLE_APP_ACCOUNTS + '/' + RAZZLE_APP_SIGNOUT + '.html?continue=' +
                encodeURIComponent(
                    document.location.origin
                    + (cntn ? (scp ? '/scope' : '/endpoint') : '/sign-in')
                    + locationRef.current.search
                ),
            500
        );
    }, [locationRef]);
  return (
    <>
    <Helmet>
        <title>Welcome to Ottry sign out</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <div>Sign out...</div>
    </>
  );
});

export default React.memo(() => {
  return (
    <ErrorBoundary {...{FallbackComponent}}>
      <SignOut/>
    </ErrorBoundary>
  );
});

